'use client';

import { useEffect } from "react";
import * as Sentry from "@sentry/nextjs";
import { m } from 'framer-motion';
// @mui
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// layouts
import CompactLayout from 'src/layouts/compact';
// components
import { varBounce } from 'src/components/animate';

// ----------------------------------------------------------------------

export default function ErrorPage({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <CompactLayout>
      <Box flexGrow={0.7} />
      <m.div variants={varBounce().in}>
        <Typography variant="h3" paragraph>
          Algo salió mal!
        </Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <Typography sx={{ color: 'text.secondary' }}>
          Se produjo un error al representar la página correspondiente.
          <br />
          Es posible que tengas que volver a intentarlo en unos minutos.
        </Typography>
      </m.div>

      <Box flexGrow={1} />

      <Button size="large" variant="contained" color="inherit" onClick={() => reset()}>
        Intentar otra vez
      </Button>
    </CompactLayout>
  );
}
